<template>
  <div>
    <h2>Icons</h2>
    <VueListView dir="row" :isInline="false">
      <div class="icon-holder" v-for="(iconKey, index) in iconKeys" :key="`icon-${index}`">
        <VueIcon
          :iconName="icons[iconKey].name"
          :width="icons[iconKey].width"
          :height="icons[iconKey].height"
        />
      </div>
    </VueListView>

    <div>
      <BrandPickerModal></BrandPickerModal>
    </div>
  </div>
</template>
<script>
import 'swiper/dist/css/swiper.css';

import VueListView from '@/components/shared/VueListView/VueListView.vue';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import BrandPickerModal from '@/components/brand/Modals/BrandModal/BrandPickerModal.vue';

export default {
  name: 'Feed',
  components: {
    VueListView,
    VueIcon,
    BrandPickerModal,
  },
  computed: {
    icons() {
      return ICON_VARIABLES;
    },
    iconKeys() {
      const iconKeys = Object.keys(ICON_VARIABLES).map(key => key);

      return iconKeys;
    },
  },
  data() {
    return {
      smallSliderOption: {
        slidesPerView: 1.2,
        centeredSlides: true,
        // spaceBetween: 10,
        grabCursor: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      imageSliderOption: {
        slidesPerView: 1,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      items: [
        {
          price: '500TL',
          day: 'Çarşamba',
          img: `${require('@/assets/logos/türk-tuborg.jpg')}`,
          bgUrl: `url(${require('@/assets/bg/bg-login-main.jpg')})`,
        },
        {
          img: `${require('@/assets/logos/türk-tuborg.jpg')}`,
          text: 'Sütaşın sana özel, YAP KAZAN ve KAZI KAZAN oyunlarını oyna puanları topla.',
          headline: 'Şütaştan hediyeler',
          bgUrl: `url(${require('@/assets/bg/bg-login-main.jpg')})`,
          imgHeadline: 'Arabayla Uzun Yola Çıkacaklara 10 Tavsiye',
        },
        {
          price: '100TL',
          day: 'Perşembe',
          img: `${require('@/assets/logos/türk-tuborg.jpg')}`,
          bgUrl: `url(${require('@/assets/bg/bg-login-main.jpg')})`,
        },
        {
          price: '500TL',
          day: 'Pazartesi',
          img: `${require('@/assets/logos/türk-tuborg.jpg')}`,
          bgUrl: `url(${require('@/assets/bg/bg-login-main.jpg')})`,
          imgHeadline: 'Arabayla Uzun Yola Çıkacaklara 10 Tavsiye',
        },
        {
          price: '560TL',
          day: 'Perşembe',
          img: `${require('@/assets/logos/türk-tuborg.jpg')}`,
          bgUrl: `url(${require('@/assets/bg/bg-login-main.jpg')})`,
          imgHeadline: 'Arabayla Uzun Yola Çıkacaklara 10 Tavsiye',
        },
        {
          price: '300TL',
          day: 'Cuma',
          img: `${require('@/assets/logos/türk-tuborg.jpg')}`,
          bgUrl: `url(${require('@/assets/bg/bg-login-main.jpg')})`,
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.small-slider-container {
  .swiper-slide {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
}

.image-slider-container {
  .swiper-slide {
    width: 100%;
    height: 250px;
  }
}
</style>
